export default [
    {
        id: "do_1v1JOwAAAYwnOLSF",
        date_send: "2023-12-01T21:11:16.869Z",
        receptor: {
            name: 'Corporación Tecnológica',
            tax_id: '20123456789'
        },
        email: "juanperez@gmail.com",
        message: {
            text: '',
            recipient: '',
            subject: 'Lorem lorem',
            type: ['pdf', 'xml']
        },
        status: 'send', // 'send', 'open', 'reboot', 'received' or 'spam'
        acc_id: "acc_md850oAYz2vRsAGyC5",
        description: "T011-00009",
        total_open: 0,
        total_clicks: 0,
        document_type: 'CL33',
        number: 'F001 - 28064335'
    },
    {
        id: "do_1v1JOwAAAYwnOL45",
        date_send: "2023-12-01T21:11:16.869Z",
        receptor: {
            name: 'Servicios Logísticos del N...',
            tax_id: '20654321987'
        },
        email: "marialopez@hotmail.com",
        message: {
            text: '',
            recipient: '',
            subject: 'Lorem lorem',
            type: ['pdf', 'xml']
        },
        status: 'open', // 'send', 'open', 'reboot', 'received' or 'spam'
        acc_id: "acc_md850o645ytvRsAGyC5",
        description: "",
        total_open: 1,
        total_clicks: 3,
        document_type: 'CL39',
        number: 'B002 - 59459061'
    },
    {
        id: "do_1v1JOwAAAYw76nOLSF",
        date_send: "2023-12-01T21:11:16.869Z",
        receptor: {
            name: 'Constructora Inmobiliari...',
            tax_id: '20987654321'
        },
        email: "andres.garcia@yahoo.com",
        message: {
            text: '',
            recipient: '',
            subject: 'Lorem lorem',
            type: ['pdf', 'xml']
        },
        status: 'reboot', // 'send', 'open', 'reboot', 'received' or 'spam'
        acc_id: "acc_md850oAYz2vRsAGyC5",
        description: "",
        total_open: 0,
        total_clicks: 0,
        document_type: 'CL112',
        number: 'NCR39 - 15157949'
    },
    {
        id: "do_1v1JOwAAAYwnO675F",
        date_send: "2023-12-01T21:11:16.869Z",
        receptor: {
            name: 'Distribuidora de Alimento...',
            tax_id: '20321456789'
        },
        email: "lauratorres@gmail.com",
        message: {
            text: '',
            recipient: '',
            subject: 'Lorem lorem',
            type: ['pdf', 'xml']
        },
        status: 'received', // 'send', 'open', 'reboot', 'received' or 'spam'
        acc_id: "acc_md850oAYz2vRsAGyC5",
        description: "",
        total_open: 0,
        total_clicks: 0,
        document_type: 'CL33',
        number: 'F001 - 43597934'
    },
    {
        id: "do_167OwAAAYwnOLSF",
        date_send: "2023-12-01T21:11:16.869Z",
        receptor: {
            name: 'Agroexportadora Los And...',
            tax_id: '20765432198'
        },
        email: "pedrorodriguez@gmail.com",
        message: {
            text: '',
            recipient: '',
            subject: 'Lorem lorem',
            type: ['pdf', 'xml']
        },
        status: 'reboot', // 'send', 'open', 'reboot', 'received' or 'spam'
        acc_id: "acc_md850oAYz2vRsAGyC5",
        description: "",
        total_open: 0,
        total_clicks: 0,
        document_type: 'CL33',
        number: 'F001 - 35617260'
    },
    {
        id: "do_1v1768AAAYwnOLSF",
        date_send: "2023-12-01T21:11:16.869Z",
        receptor: {
            name: 'Consultoría Empresarial ...',
            tax_id: '20567891234'
        },
        email: "anacastro@hotmail.com",
        message: {
            text: '',
            recipient: '',
            subject: 'Lorem lorem',
            type: ['pdf', 'xml']
        },
        status: 'spam', // 'send', 'open', 'reboot', 'received' or 'spam'
        acc_id: "acc_md850oAYz2vRsAGyC5",
        description: "",
        total_open: 0,
        total_clicks: 0,
        document_type: 'CL39',
        number: 'B002 - 58656520'
    },
    {
        id: "do_1v1JOwAAAYwnO89F",
        date_send: "2023-12-01T21:11:16.869Z",
        receptor: {
            name: 'Industrias Metalúrgicas P...',
            tax_id: '20234567891'
        },
        email: "carlosgomez@yahoo.com",
        message: {
            text: '',
            recipient: '',
            subject: 'Lorem lorem',
            type: ['pdf', 'xml']
        },
        status: 'send', // 'send', 'open', 'reboot', 'received' or 'spam'
        acc_id: "acc_md850oAYz2vRsAGyC5",
        description: "",
        total_open: 0,
        total_clicks: 0,
        document_type: 'CL52',
        number: 'GD001 - 89522073'
    },
    {
        id: "do_1v1JOw6456AYwnOLSF",
        date_send: "2023-12-01T21:11:16.869Z",
        receptor: {
            name: 'Turismo y Aventura Amaz...',
            tax_id: '20456789123'
        },
        email: "sofiaherrera@gmail.com",
        message: {
            text: '',
            recipient: '',
            subject: 'Lorem lorem',
            type: ['pdf', 'xml']
        },
        status: 'received', // 'send', 'open', 'reboot', 'received' or 'spam'
        acc_id: "acc_md850oAYz2vRsAGyC5",
        description: "",
        total_open: 0,
        total_clicks: 0,
        document_type: 'CL52',
        number: 'GD001 - 74025119'
    },
    {
        id: "do_1v1J87wAAAYwnOLSF",
        date_send: "2023-12-01T21:11:16.869Z",
        receptor: {
            name: 'Energía Renovable del Perú',
            tax_id: '20891234567'
        },
        email: "sofiaherrera@gmail.com",
        message: {
            text: '',
            recipient: '',
            subject: 'Lorem lorem',
            type: ['pdf', 'xml']
        },
        status: 'open', // 'send', 'open', 'reboot', 'received' or 'spam'
        acc_id: "acc_md850oAYz2vRsAGyC5",
        description: "",
        total_open: 0,
        total_clicks: 0,
        document_type: 'CL33',
        number: 'luisaramirez@hotmail.com'
    }
]
