<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn outlined @click="modalPDF=true"><v-icon left>mdi-text-box</v-icon>Ver PDF</v-btn>
        <v-btn @click="modalEmail=true" class="mx-2" color="primary"><v-icon left>mdi-email-sync</v-icon>Reenviar documento</v-btn>
      </template>
    </mini-header>

    <!-- end header -->
    <v-col class="px-0 pt-0" style="z-index: 0;">
      <skeleton-detail v-if="$store.state.purchases.retrieveLoader" />
      <div class="d-flex" v-else>
        <!-- detail card -->
        <div class="mr-6" style="min-width: 400px;">
          <div style="position:fixed!important;">
            <v-img contain :width="400" :height="110" :src="require(`@/assets/backgrounds/background-document-detail.svg`)">
              <span class="px-4 pt-4 subtitle-2 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 300px;">
                {{instance?.document_type | nameDocumentsType}}  Nº{{instance.number}}
              </span>
              <v-row class="ml-2 mt-3" align="end" no-gutters>
                <v-col cols="5" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Total</span>
                  <span class="subtitle-2 white--text">
                    <span class="subtitle-2 white--text">
                      <span class="font-weight-bold">7.775.899</span>
                      <span class="caption">CLP</span>
                    </span>
                  </span>
                </v-col>
                <v-col cols="5" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Fecha</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold">02 sep. 2022</span>
                  </span>
                </v-col>
              </v-row>
            </v-img>
            <v-card class="pa-0 mt-4" width="400" transition="slide-y-transition" min-height="calc(100% - 278px)" flat style="position:fixed!important;">
              <v-toolbar color="transparent" flat height="46">
                <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Resumen del documento</v-toolbar-title>
              </v-toolbar>
              <v-divider />

              <v-card-text class="py-4 px-5">
                <v-list class="v-list-form transparent pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 d-flex align-center"><v-icon class="mr-3" color="secondary" size="18">mdi-account</v-icon> Razón social</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.receptor.name}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 d-flex align-center"><v-icon class="mr-3" color="secondary" size="18">mdi-card-account-details</v-icon> RUC</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.receptor.tax_id}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 d-flex align-center"><v-icon class="mr-3" color="secondary" size="18">mdi-file-document</v-icon> Tipo de documento</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance?.document_type | nameDocumentsType}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 d-flex align-center"><v-icon class="mr-3" color="secondary" size="18">mdi-folder-pound</v-icon> Serie-Correlativo</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.number || '-'}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 d-flex align-center"><v-icon class="mr-3" color="secondary" size="18">mdi-card-bulleted</v-icon> ID</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.id || '-'}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 d-flex align-center"><v-icon class="mr-3" color="secondary" size="18">mdi-calendar</v-icon> Creado el</v-list-item-title>
                    <v-list-item-subtitle>{{instance.date_send | dateTime }}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <!-- end summary -->

        <!-- panels -->
        <div style="flex:1; min-width: 200px;">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="subtitle-1 secondary--text font-weight-semibold">
              <v-row align="center" no-gutters>
                <v-icon size="18" left color="secondary">mdi-email</v-icon>
                Detalle del envio
                <v-col cols="5" class="ml-2">
                  <v-autocomplete
                    v-model="filterAX"
                    hide-details
                    single-line
                    color="primary"
                    dense
                    outlined
                    :items="mailList"
                  />
                </v-col>
              </v-row>
            </v-toolbar-title>
          </v-toolbar>
          <v-card class="mt-1" flat v-if="currentUsersAx?.length">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold" style="width: 200px">
                <div class="d-flex align-center">
                  Recibido por <img class="ml-2" :src="require('@/assets/appAsset-brand--axteroid.svg')" height="20" />
                </div>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <div class="pt-2 pb-2" :class="{'px-2' : hoverAX}">
                <v-list class="pa-0">
                  <div v-for="(m, i) in (filterAX?.length > 0 &&  filterAX !== 'Todos los envios' ? currentUsersAx : currentUsersAx.slice(0,2))" :key="`${m.mail}${i}`">
                    <v-hover v-slot="{ hover }">
                      <v-card :class="(hover || hoverAX === m.mail) && filterAX === 'Todos los envios' ? 'background' : ''" rounded="sm" flat :value="hover ? hoverAX = m.mail : null">
                        <v-list-item :class="hoverAX ? 'px-3' : 'px-5'" dense>
                          <v-list-item-action class="v-list-form-tracker fontBody--text subtitle-2 font-weight-bold ma-0">
                            <v-chip :color="status.find(({code}) => m.status === code)?.color" small label>
                              {{ status.find(({code}) => m.status === code)?.key }}
                              <v-icon right>{{status.find(({code}) => m.status === code)?.icon}}</v-icon>
                            </v-chip>
                          </v-list-item-action>
                          <v-list-item-subtitle class="body-1 ml-n3">
                            <v-sheet class="fontDraw--text transparent" style="justify-content: left;">
                              <v-tooltip color="bgSearch" bottom transition="scale-transition" max-width="400" :open-on-hover="m.description?.length > 50" :nudge-bottom="-10">
                                <template v-slot:activator="{on}">
                                  <v-sheet class="fontDraw--text transparent" v-on="m.description?.length > 50 ? on : false" style="justify-content: left;">
                                    <span class="font-weight-medium secondary--text d-inline-block text-truncate" style="max-width: 95%;">{{m.description}}</span>
                                  </v-sheet>
                                </template>
                                <span class="d-block px-3 py-2">{{m.description}}</span>
                              </v-tooltip>
                            </v-sheet>
                          <span class="d-block caption fontDraw--text mt-n2">{{m.mail }}</span>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-card>
                    </v-hover>
                  </div>
                  <template v-if="show1">
                    <div v-for="(m, i) in usersAx.slice(2,5)" :key="i">
                      <v-hover v-slot="{ hover }">
                        <v-card :class="(hover || hoverAX === m.mail) && filterAX === 'Todos los envios' ? 'background' : ''" rounded="sm" flat :value="hover ? hoverAX = m.mail : null">
                          <v-list-item class="px-3" dense>
                            <v-list-item-action class="v-list-form-tracker fontBody--text subtitle-2 font-weight-bold ma-0">
                              <v-chip :color="status.find(({code}) => m.status === code)?.color" small label>
                                {{ status.find(({code}) => m.status === code)?.key }}
                                <v-icon right>{{status.find(({code}) => m.status === code)?.icon}}</v-icon>
                              </v-chip>
                            </v-list-item-action>
                            <v-list-item-subtitle class="body-1 ml-n3">
                              <v-sheet class="fontDraw--text transparent" style="justify-content: left;">
                                <v-tooltip color="bgSearch" bottom transition="scale-transition" max-width="400" :open-on-hover="m.description?.length > 50" :nudge-bottom="-10">
                                  <template v-slot:activator="{on}">
                                    <v-sheet class="fontDraw--text transparent" v-on="m.description?.length > 50 ? on : false" style="justify-content: left;">
                                      <span class="font-weight-medium secondary--text d-inline-block text-truncate" style="max-width: 95%;">{{m.description}}</span>
                                    </v-sheet>
                                  </template>
                                  <span class="d-block px-3 py-2">{{m.description}}</span>
                                </v-tooltip>
                              </v-sheet>
                            <span class="d-block caption fontDraw--text mt-n2">{{m.mail }}</span>
                            </v-list-item-subtitle>
                          </v-list-item>
                        </v-card>
                      </v-hover>
                    </div>
                  </template>
                </v-list>
              </div>
            </v-card-text>
            <template v-if="!filterAX || filterAX === 'Todos los envios'">
              <v-divider />
              <v-card-actions class="py-1 px-5">
                <v-btn class="body-2" @click="show1 = !show1" text color="primary">Ver {{ show1 ? 'menos' : 'todos los' }} envíos</v-btn>
              </v-card-actions>
            </template>
          </v-card>
          <v-card class="mt-5" flat v-if="currentUsersSupplier?.length">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold" style="width: 300px">
                <div class="d-flex align-center">
                  Recibido por el proveedor de correo <img class="ml-2" :src="require('@/assets/product-outbound/example.svg')" height="20" />
                </div>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <div class="pt-2 pb-2" :class="{'px-2' : hoverAX}">
                <v-list class="pa-0">
                  <div v-for="(m, i) in (filterAX?.length > 0 &&  filterAX !== 'Todos los envios' ? currentUsersSupplier : currentUsersSupplier.slice(0,2))" :key="`${m.mail}${i}`">
                    <v-hover v-slot="{ hover }">
                      <v-card :class="(hover || hoverAX === m.mail) && filterAX === 'Todos los envios' ? 'background' : ''" rounded="sm" flat :value="hover ? hoverAX = m.mail : null">
                        <v-list-item class="px-3" dense>
                          <v-list-item-action class="v-list-form-tracker fontBody--text subtitle-2 font-weight-bold ma-0">
                            <v-chip :color="status.find(({code}) => m.status === code)?.color" small label>
                              {{ status.find(({code}) => m.status === code)?.key }}
                              <v-icon right>{{status.find(({code}) => m.status === code)?.icon}}</v-icon>
                            </v-chip>
                          </v-list-item-action>
                          <v-list-item-subtitle class="body-1 ml-n3">
                            <v-sheet class="fontDraw--text transparent" style="justify-content: left;">
                              <v-tooltip color="bgSearch" bottom transition="scale-transition" max-width="400" :open-on-hover="m.description?.length > 50" :nudge-bottom="-10">
                                <template v-slot:activator="{on}">
                                  <v-sheet class="fontDraw--text transparent" v-on="m.description?.length > 50 ? on : false" style="justify-content: left;">
                                    <span class="font-weight-medium secondary--text d-inline-block text-truncate" style="max-width: 95%;">{{m.description}}</span>
                                  </v-sheet>
                                </template>
                                <span class="d-block px-3 py-2">{{m.description}}</span>
                              </v-tooltip>
                            </v-sheet>
                          <span class="d-block caption fontDraw--text mt-n2">{{m.mail }}</span>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-card>
                    </v-hover>
                  </div>
                  <template v-if="show3">
                    <div v-for="(m, i) in currentUsersSupplier.slice(2,5)" :key="i">
                      <v-hover v-slot="{ hover }">
                        <v-card :class="(hover || hoverAX === m.mail) && filterAX === 'Todos los envios' ? 'background' : ''" rounded="sm" flat :value="hover ? hoverAX = m.mail : null">
                          <v-list-item class="px-3" dense>
                            <v-list-item-action class="v-list-form-tracker fontBody--text subtitle-2 font-weight-bold ma-0">
                              <v-chip :color="status.find(({code}) => m.status === code)?.color" small label>
                                {{ status.find(({code}) => m.status === code)?.key }}
                                <v-icon right>{{status.find(({code}) => m.status === code)?.icon}}</v-icon>
                              </v-chip>
                            </v-list-item-action>
                            <v-list-item-subtitle class="body-1 ml-n3">
                              <v-sheet class="fontDraw--text transparent" style="justify-content: left;">
                                <v-tooltip color="bgSearch" bottom transition="scale-transition" max-width="400" :open-on-hover="m.description?.length > 50" :nudge-bottom="-10">
                                  <template v-slot:activator="{on}">
                                    <v-sheet class="fontDraw--text transparent" v-on="m.description?.length > 50 ? on : false" style="justify-content: left;">
                                      <span class="font-weight-medium secondary--text d-inline-block text-truncate" style="max-width: 95%;">{{m.description}}</span>
                                    </v-sheet>
                                  </template>
                                  <span class="d-block px-3 py-2">{{m.description}}</span>
                                </v-tooltip>
                              </v-sheet>
                            <span class="d-block caption fontDraw--text mt-n2">{{m.mail }}</span>
                            </v-list-item-subtitle>
                          </v-list-item>
                        </v-card>
                      </v-hover>
                    </div>
                  </template>
                </v-list>
              </div>
            </v-card-text>
            <template v-if="!filterAX || filterAX === 'Todos los envios'">
              <v-divider />
              <v-card-actions class="py-1 px-5">
                <v-btn class="body-2" @click="show3 = !show3" text color="primary">Ver {{ show3 ? 'menos' : 'todos los' }} envíos</v-btn>
              </v-card-actions>
            </template>
          </v-card>

          <v-card class="mt-5" flat v-if="currentUsers?.length">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold" style="width: 300px">
                <div class="d-flex align-center">
                  Recibido por el destinatario <v-icon class="ml-1" color="secondary" small>mdi-account</v-icon>
                </div>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <div class="pt-2 pb-2" :class="{'px-2' : hoverAX}">
                <v-list class="pa-0">
                  <div v-for="(m, i) in (filterAX?.length > 0 &&  filterAX !== 'Todos los envios' ? currentUsers : currentUsers.slice(0,2))" :key="`${m.mail}${i}`">
                    <v-hover v-slot="{ hover }">
                      <v-card :class="(hover || hoverAX === m.mail) && filterAX === 'Todos los envios' ? 'background' : ''" rounded="sm" flat :value="hover ? hoverAX = m.mail : null">
                        <v-list-item class="px-3" dense>
                          <v-list-item-action class="v-list-form-tracker fontBody--text subtitle-2 font-weight-bold ma-0">
                            <v-chip :color="status.find(({code}) => m.status === code)?.color" small label>
                              {{ status.find(({code}) => m.status === code)?.key }}
                              <v-icon right>{{status.find(({code}) => m.status === code)?.icon}}</v-icon>
                            </v-chip>
                          </v-list-item-action>
                          <v-list-item-subtitle class="body-1 ml-n3">
                            <v-sheet class="fontDraw--text transparent" style="justify-content: left;">
                              <v-tooltip color="bgSearch" bottom transition="scale-transition" max-width="400" :open-on-hover="m.description?.length > 50" :nudge-bottom="-10">
                                <template v-slot:activator="{on}">
                                  <v-sheet class="fontDraw--text transparent" v-on="m.description?.length > 50 ? on : false" style="justify-content: left;">
                                    <span class="font-weight-medium secondary--text d-inline-block text-truncate" style="max-width: 95%;">{{m.description}}</span>
                                  </v-sheet>
                                </template>
                                <span class="d-block px-3 py-2">{{m.description}}</span>
                              </v-tooltip>
                            </v-sheet>
                          <span class="d-block caption fontDraw--text mt-n2">{{m.mail }}</span>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-card>
                    </v-hover>
                  </div>
                  <template v-if="show2">
                    <div v-for="(m, i) in currentUsers.slice(2,5)" :key="i">
                      <v-hover v-slot="{ hover }">
                        <v-card :class="(hover || hoverAX === m.mail) && filterAX === 'Todos los envios' ? 'background' : ''" rounded="sm" flat :value="hover ? hoverAX = m.mail : null">
                          <v-list-item class="px-3" dense>
                            <v-list-item-action class="v-list-form-tracker fontBody--text subtitle-2 font-weight-bold ma-0">
                              <v-chip :color="status.find(({code}) => m.status === code)?.color" small label>
                                {{ status.find(({code}) => m.status === code)?.key }}
                                <v-icon right>{{status.find(({code}) => m.status === code)?.icon}}</v-icon>
                              </v-chip>
                            </v-list-item-action>
                            <v-list-item-subtitle class="body-1 ml-n3">
                              <v-sheet class="fontDraw--text transparent" style="justify-content: left;">
                                <v-tooltip color="bgSearch" bottom transition="scale-transition" max-width="400" :open-on-hover="m.description?.length > 50" :nudge-bottom="-10">
                                  <template v-slot:activator="{on}">
                                    <v-sheet class="fontDraw--text transparent" v-on="m.description?.length > 50 ? on : false" style="justify-content: left;">
                                      <span class="font-weight-medium secondary--text d-inline-block text-truncate" style="max-width: 95%;">{{m.description}}</span>
                                    </v-sheet>
                                  </template>
                                  <span class="d-block px-3 py-2">{{m.description}}</span>
                                </v-tooltip>
                              </v-sheet>
                            <span class="d-block caption fontDraw--text mt-n2">{{m.mail }}</span>
                            </v-list-item-subtitle>
                          </v-list-item>
                        </v-card>
                      </v-hover>
                    </div>
                  </template>
                </v-list>
              </div>
            </v-card-text>
            <template v-if="!filterAX || filterAX === 'Todos los envios'">
              <v-divider />
              <v-card-actions class="py-1 px-5">
                <v-btn class="body-2" @click="show2 = !show2" text color="primary">Ver {{ show2 ? 'menos' : 'todos los' }} envíos</v-btn>
              </v-card-actions>
            </template>
          </v-card>

        </div>
      </div>
    </v-col>
    <!-- dialog pdf -->
    <v-dialog v-model="modalPDF" width="700" persistent :scrollable="true" no-click-animation overlay-color="overlay">
      <render-pdf :basicRender="true" :documentType="instance.document_type | nameDocumentsType" :number="instance.number" @close="modalPDF=false" :loaderRender="loaderRender" :renderUrl="renderUrl" :flat="true" :showFormat="true"  />
    </v-dialog>
    <!-- end dialog pdf -->

    <!-- email -->
    <v-dialog v-model="modalEmail" width="700" persistent :scrollable="true" no-click-animation overlay-color="overlay">
      <v-card min-height="300px">
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Enviar documento por mail</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="modalEmail=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0" >
          <v-col class="pa-0 pb-8">
            <v-card class="transparent rounded-0 pa-0" flat>
              <v-card-subtitle class="background pt-5 pb-3 px-5">
                <div class="d-flex align-center">
                  <span class="fontBody--text body-1" style="min-width: 60px;">Para</span>
                  <v-combobox class="a-combobox" v-model="$v.messages.recipient.$model" :error="$v.messages.recipient.$error" hide-details :items="[]" outlined required hide-selected :placeholder="!messages.recipient ? 'Escribe el email del destinatario' : ''" @change="setEmails" multiple hide-no-data deletable-chips small-chips single-line dense autocomplete="off">
                    <template v-slot:selection="data">
                      <v-chip class="pr-2 ma-1" small v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">{{ data.item }}</v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center mt-3">
                  <span class="fontBody--text body-1" style="min-width: 60px;">Asunto</span>
                  <v-text-field v-model="$v.messages.subject.$model" :error="$v.messages.subject.$error" outlined required single-line dense maxlength="60" hint="Escribe un asunto para el mensaje" persistent-hint :hide-details="!$v.messages.subject.$error" />
                </div>
                <v-col class="pa-0 mt-2">
                  <span class="fontBody--text body-1 mr-5">Archivos adjuntos</span>
                  <v-chip class="ma-1 pl-1 pr-2" color="defaultGrey" small outlined label>
                    <v-checkbox class="ma-0 pb-1" v-model="messages.type" value="pdf" color="primary" dense hide-details />
                    <v-icon left size="14">mdi-paperclip</v-icon>{{$store.state?.auth?.account?.country === 'PE' ? instance.number?.replace(/[ ]/g, '') : instance.number}}.pdf
                  </v-chip>
                  <v-chip class="ma-1 pl-1 pr-2" color="defaultGrey" small outlined label>
                    <v-checkbox class="ma-0 pb-1" v-model="messages.type" value="xml" dense hide-details />
                    <v-icon left size="14">mdi-paperclip</v-icon>{{$store.state?.auth?.account?.country === 'PE' ? instance.number?.replace(/[ ]/g, '') : instance.number}}.xml
                  </v-chip>
                </v-col>
              </v-card-subtitle>
              <v-divider />
              <v-card-text class="px-0 mt-2 mx-auto bgPanel text-center" style="max-height: 450px;">
                <v-alert border="top" class="pa-0 mx-auto" color="var(--light-blue-primary)" colored-border elevation="0" :style="`width: ${$vuetify.breakpoint.width < 1270 ? 465 : 564}px;`" />
                  <v-sheet class="mx-auto" :width="$vuetify.breakpoint.width < 1270 ? 470 : 570" style="border-radius: 10px; margin-top: -17px;" outlined>
                    <reminder-documents-email :instance="instance" :activeDocument="activeDocument" :color-background="account.color_background" :message.sync="messages.text" />
                  </v-sheet>
              </v-card-text>
            </v-card>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4 px-5">
          <v-spacer />
          <v-btn @click="dialogMessages=false" outlined>Cancelar</v-btn>
          <v-btn :loading="$store.state.messages.createLoader" color="primary">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end email -->
  </v-row>
</template>

<script>
  import { minLength, maxLength, required } from 'vuelidate/lib/validators'
  import emailsTracker from '@/data/emailsTracker' // example
  import MiniHeader from '@/components/commons/MiniHeader'
  // import cloneDeep from 'lodash/cloneDeep'
  import ReminderDocumentsEmail from '@/components/reminders/documents/Email'
  import VModal from '@/components/commons/VPanel'
  import RenderPdf from '@/components/files/RenderPdf'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import RenderViewMixin from '@/mixins/RenderViewMixin'
  import DocumentTypes from '@/collections/documentTypes'

  export default {
    mixins: [
      GenericViewMixin,
      RenderViewMixin
    ],
    components: {
      VModal,
      RenderPdf,
      MiniHeader,
      ReminderDocumentsEmail
    },
    data: () => ({
      hoverAX: null,
      currentUsersAx: [],
      filterAX: 'Todos los envios',
      currentUsersSupplier: [],
      currentUsers: [],
      mailList: ['Todos los envios', 'johndoe@constructoraperez.com', 'sarahsmith@constructoraperez.com', 'emilywilson@constructoraperez.com', 'mikebrown@constructoraperez.com', 'davidjones@constructoraperez.com', 'mikebrown@constructoraperez.com', 'robertjohnson@constructoraperez.com'],
      usersAx: [
        {
            status: 'open',
            description: 'Correo enviado el 02 de feb. a las 12:23.',
            mail: 'johndoe@constructoraperez.com'
        },
        {
            status: 'open',
            description: 'Correo enviado el 02 de feb. a las 12:23.',
            mail: 'sarahsmith@constructoraperez.com'
        },
        {
            status: 'send',
            description: 'Correo enviado el 02 de feb. a las 12:23.',
            mail: 'emilywilson@constructoraperez.com'
        },
        {
            status: 'reboot',
            description: 'El correo enviado el 02 de feb. a las 12:23  no fue entregado por Axteroid.',
            mail: 'mikebrown@constructoraperez.com'
        },
        {
            status: 'reboot',
            description: 'Correo enviado el 03 de feb. a las 12:23.',
            mail: 'sarahsmith@constructoraperez.com'
        }
      ],
      usersSupplier: [
          {
              status: 'open',
              description: 'Correo enviado el 02 de feb. a las 12:23.',
              mail: 'johndoe@constructoraperez.com'
          },
          {
              status: 'open',
              description: 'Correo enviado el 02 de feb. a las 12:23.',
              mail: 'sarahsmith@constructoraperez.com'
          },
          {
              status: 'reboot',
              description: 'El correo enviado el 02 de feb. a las 12:23  no fue entregado por Axteroid.',
              mail: 'davidjones@constructoraperez.com'
          },
          {
              status: 'send',
              description: 'Correo enviado el 03 de feb. a las 12:23.',
              mail: 'emilywilson@constructoraperez.comm'
          },
          {
              status: 'reboot',
              description: 'El correo enviado el 02 de feb. a las 12:23  no fue entregado por Axteroid.',
              mail: 'mikebrown@constructoraperez.com'
          }
      ],
      users: [
        {
            status: 'open',
            description: 'Correo enviado el 02 de feb. a las 12:23.',
            mail: 'sarahsmith@constructoraperez.com'
        },
        {
            status: 'open',
            description: 'El correo enviado el 02 de feb. a las 12:23 no fue entregado debido a a que el buzón de correo está lleno.',
            mail: 'davidjones@constructoraperez.com'
        },
        {
            status: 'received',
            description: 'El correo enviado el 02 de feb. a las 12:23 fue recibido el 02 de feb. a las 12:25.',
            mail: 'emilywilson@constructoraperez.coms'
        },
        {
            status: 'reboot',
            description: 'El correo enviado el 02 de feb. a las 12:23 no fue entregado debido a que la dirección es incorrecta.',
            mail: 'mikebrown@constructoraperez.com'
        },
        {
            status: 'spam',
            description: 'El correo enviado el 02 de feb. a las 12:23 no fue entregado debido a que cayó en la bandeja de SPAM.',
            mail: 'robertjohnson@constructoraperez.com'
        }
    ],
      show1: false,
      show2: false,
      show3: false,
      modalEmail: false,
      modalPDF: false,
      status: [
        {
          key: 'Enviado',
          code: 'send',
          icon: 'mdi-email-fast',
          color: 'lightBlue'
        },
        {
          key: 'Recibido',
          code: 'received',
          icon: 'mdi-email-check',
          color: 'yellowLight'
        },
        {
          key: 'Abierto',
          code: 'open',
          icon: 'mdi-email-open',
          color: 'greenExtraLight'
        },
        {
          key: 'Rebotado',
          code: 'reboot',
          icon: 'mdi-email-arrow-left',
          color: 'blueGreyLighten'
        },
        {
          key: 'SPAM',
          code: 'spam',
          icon: 'mdi-email-remove',
          color: 'redLight'
        }
      ],
      instance: {},
      scroll: 0,
      breadcrumbs: {
        main: 'Email tracker',
        children: [
          { text: '' }
        ]
      },
      messages: {
        text: '',
        recipient: '',
        subject: '',
        type: ['pdf', 'xml']
      }
  }),
  computed: {
    activeDocument () {
      return DocumentTypes.find((d) => d.id === this.instance.document_type)
    }
    // currentUsersAx () {
    //   if (!this.filterAX.length) return this.usersAx.slice(1, 2)
    //   return this.usersAx.filter(({mail}) => mail === this.filterAX).slice(1, 2)
    // }
  },
  created () {
    this.instance = emailsTracker.find(({id}) => id === this.$route.params.id)
    this.currentUsersAx = [...this.usersAx]
    this.currentUsersSupplier = [...this.usersSupplier]
    this.currentUsers = [...this.users]
    this.breadcrumbs.children[0] = {
      text: `${this.nameDocumentsType(this.instance?.document_type)}  Nº${this.instance.number}`
    }
  },
  watch: {
    hover (val) {
      console.log('hover', val)
    },
    modalPDF (val) {
      this.modalRender = val
      if (!this.renderUrl) this.renderDocument2('documents')
    },
    filterAX (val) {
      console.log('filterAX', val)
      // if (val === 'Todos los envios') this.currentUsersAx = this.usersAx
      if (val?.length > 0 && val !== 'Todos los envios') {
        this.show1 = false
        this.show2 = false
        this.show3 = false
        this.currentUsersAx = this.usersAx.filter(({mail}) => mail === this.filterAX)
        this.currentUsersSupplier = this.usersSupplier.filter(({mail}) => mail === this.filterAX)
        this.currentUsers = this.users.filter(({mail}) => mail === this.filterAX)
      } else {
        this.currentUsersAx = [...this.usersAx]
        this.currentUsersSupplier = [...this.usersSupplier]
        this.currentUsers = [...this.users]
      }
    }
  },
  methods: {
    test (h) {
      console.log('hover', h)
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    setEmails (e) {
      let array = []
      e.forEach(item => {
        let elm = item.replace(/ /g, ',')
        array = [...array, ...elm.split(',')]
      })
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      this.messages.recipient = array.filter(item => regex.test(item))
    }
  },
  validations: {
    messages: {
      recipient: {
        required
      },
      subject: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(60)
      },
      text: {
        required
      }
    }
  }
}
</script>